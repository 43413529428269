import React from 'react';

export const NonExistentUser: React.FC = () => (
    <div className="pt-5 text-center">
        <div className="col-12 align-items-center">
            <i className="mb-4 icon icon-xl text-primary line-height-1 material-icons-outlined">info</i>
            <h4 className="mb-2" id="exampleModalLabel2">
                Selected contact is not created in the subscription tool
            </h4>
        </div>
    </div>
);
