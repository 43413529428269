import React from 'react';
import { NotificationToast } from '.';
import { ToastMessage } from './ToastMessage';

export const NotificationFailedToast: React.FC<NotificationToast> = props => {
    const { count, closeToast, itemMessage } = props;
    const context = props.context === 'add' ? 'add' : 'close';
    const itemsString = props.count > 1 ? 'items' : 'item';
    const itemMsg = itemMessage ? itemMessage : `${count} ${itemsString}`;
    return <ToastMessage
        title="Error"
        message={`${itemMsg} ${context} failed`}
        closeToast={closeToast}
    />;
};
