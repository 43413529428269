import React from 'react';
import { NotificationMessage } from 'components/contexts/NotificationsContext';
import { NotificationToast } from '.';
import { ToastMessage } from './ToastMessage';

type NotificationErrorToastProps = Pick<NotificationMessage, 'context'> &
    Pick<NotificationMessage, 'message'> &
    Pick<NotificationToast, 'closeToast'>;

export const NotificationErrorToast: React.FC<
    NotificationErrorToastProps
> = props => {
    const context = props.context === 'add' ? 'adding' : 'closing';
    const message = props.message || `An error occured when ${context} subscriptions.`;
    return <ToastMessage
        title="Error"
        message={message}
        closeToast={props.closeToast}
    />;
};
