import React from 'react';
import { RouteComponentProps, withRouter } from 'components/common/Router';
import { UserInformation } from 'core/models/userInformation';
import { getConfiguration } from 'core/configuration/configurationLoader';
import { fetcher } from 'core/http/fetcher';
import { AuthenticationContext } from './AuthenticationContext';
import { getIcIdQueryParameter } from 'core/utils';
import { Loading } from 'components/common/Loading';
import { logTechnical } from 'core/logging/logger';

const defaultCurrentUser = null as UserInformation | null;

const initialState = {
    currentUser: defaultCurrentUser,
    isManagedContact: false,
    isLoading: true,
};

type UserProviderState = Readonly<typeof initialState>;
export type CurrentUserContextState = UserProviderState;

const context = React.createContext<CurrentUserContextState>(initialState);
const { Provider, Consumer } = context;

class CurrentUserProvider extends React.Component<
    RouteComponentProps,
    UserProviderState
> {
    public static contextType = AuthenticationContext;
    public readonly state: UserProviderState = initialState;
    declare context: React.ContextType<typeof AuthenticationContext>;

    public async componentDidMount() {
        const icIdQueryParameter = getIcIdQueryParameter(
            this.props.location.search,
        );
        await this.loadCurrentUser(icIdQueryParameter);
    }

    public async componentDidUpdate(prevProps: RouteComponentProps) {
        const currentIcIdQueryParameter = getIcIdQueryParameter(
            this.props.location.search,
        );
        const previousIcIdQueryParameter = getIcIdQueryParameter(
            prevProps.location.search,
        );

        if (currentIcIdQueryParameter !== previousIcIdQueryParameter) {
            await this.loadCurrentUser(currentIcIdQueryParameter);
        }
    }

    public render() {
        if (this.state.isLoading) {
            return (
                <div className="d-flex justify-content-center">
                    <Loading text="We are setting up your account" />
                </div>
            );
        }
        return <Provider value={this.state}>{this.props.children}</Provider>;
    }

    private async loadCurrentUser(icIdQueryParameter: string | null) {
        const contactId =
            icIdQueryParameter
            || this.context.authenticatedUser?.icId
            || '';
        const isManagedContact = !!icIdQueryParameter;

        this.setState({
            isLoading: true,
        });

        await checkAndSynchroniseUser(contactId);

        const userInformation = await fetchUserInformation(contactId);
        this.setState(
            updateCurrentUser(userInformation, isManagedContact),
            () => this.setState({ isLoading: false }),
        );
    }
}

const fetchUserInformation = async (
    contactId: string,
): Promise<UserInformation | null> => {
    try {
        const { gatewayUrl } = getConfiguration();
        const userInformation = await fetcher<UserInformation>(
            `${gatewayUrl}/v1/users/${contactId}`,
            'GET',
        );
        return userInformation;
    } catch (error) {
        return null;
    }
};

const updateCurrentUser = (
    userInformation: UserInformation | null,
    isManagedContact: boolean,
) => (prevState: CurrentUserContextState): CurrentUserContextState => {
    if (userInformation && userInformation.user) {
        return {
            ...prevState,
            currentUser: userInformation,
            isManagedContact,
        };
    }

    return prevState;
};

const provider = withRouter(CurrentUserProvider);

export {
    provider as CurrentUserProvider,
    Consumer as CurrentUserConsumer,
    context as CurrentUserContext,
};

const checkAndSynchroniseUser = async (contactId: string): Promise<void> => {
    try {
        const { gatewayUrl } = getConfiguration();
        await fetcher(
            `${gatewayUrl}/v1/users/${contactId}/check-and-synchronize`,
            'POST',
            undefined,
            false,
        );
    } catch (error) {
        if (error instanceof Error) {
            logTechnical('error', error.message, { stack: error.stack || '' });
        }
    }
};
