import React from 'react';
import { logTechnical } from 'core/logging/logger';

type ErrorBoundaryState = {
    error: Error | null;
};

export type ErrorBoundaryProps = {
    error: Error;
};

export const withErrorBoundary = (
    WrappedComponent: React.ComponentType<ErrorBoundaryProps>,
) => {
    interface IErrorBoundaryProps {
        children?: React.ReactNode;
    }

    return class ErrorBoundary extends React.Component<IErrorBoundaryProps, ErrorBoundaryState> {
        public static getDerivedStateFromError(error: Error) {
            return { error };
        }

        constructor(props: IErrorBoundaryProps) {
            super(props);
            this.state = {
                error: null,
            };
        }

        public componentDidCatch(error: Error | null) {
            this.setState({ error });

            if (error) {
                logTechnical('error', 'An unknown error has occured', {
                    message: error.message,
                    stack: error.stack || '',
                });
            }
        }

        public render() {
            const { children } = this.props;
            if (this.state.error) {
                return <WrappedComponent error={this.state.error} />;
            }

            return children;
        }
    };
};
