import React from 'react';
import { getConfiguration } from 'core/configuration/configurationLoader';
import { fetcher } from 'core/http/fetcher';
import { logTechnical } from 'core/logging/logger';
import { AuthenticatedUser } from 'core/models/authenticatedUser';
import { UserInformation } from 'core/models/userInformation';

const defaultUser = null as AuthenticatedUser | null;

const initialState = {
    authenticatedUser: defaultUser,
    isLoading: true,
};

type AuthenticationProviderState = Readonly<typeof initialState>;

export type AuthenticationContextState = AuthenticationProviderState;

const context = React.createContext<AuthenticationContextState>(initialState);
const { Provider, Consumer } = context;

class AuthenticationProvider extends React.Component<
    { children?: React.ReactNode },
    AuthenticationProviderState
> {
    public readonly state: AuthenticationProviderState = initialState;

    public async componentDidMount() {
        const { sgConnect, gatewayUrl } = getConfiguration();
        try {
            const sgConnectUser = await fetcher<SgConnectUserInfo>(
                `${sgConnect.authorizationEndpoint}/oauth2/userinfo`,
                'GET',
            );

            if (!this.checkAuthorization(sgConnectUser)) {
                this.setState({ isLoading: false });
                return;
            }

            const userInformation = await fetcher<UserInformation>(
                `${gatewayUrl}/v1/users/${sgConnectUser.contact_id}`,
                'GET',
            );

            this.setState(
                updateAuthenticatedUser(sgConnectUser, userInformation),
                () => this.setState({ isLoading: false }),
            );
        } catch (error) {
            if (error instanceof Error) {
                logTechnical('error', error.message, { stack: error.stack || '' });
            }
            this.setState({ authenticatedUser: null, isLoading: false });
        }
    }

    public render() {
        return <Provider value={this.state}>{this.props.children}</Provider>;
    }

    private checkAuthorization(user: SgConnectUserInfo) {
        const { sgConnect } = getConfiguration();
        const permissions = user.user_authorization
            .map(ua => ua.permissions)
            .reduce((m, c) => {
                return m.concat(c);
            }, [])
            .map(p => p.name);
        const hasAccess = permissions.findIndex(p => p === 'SGM_DEFAULT');
        if (hasAccess === -1) {
            window.location.assign(sgConnect.unauthorizationEndpoint);
            return false;
        }
        return true;
    }
}

const updateAuthenticatedUser = (
    sgConnectUser: SgConnectUserInfo,
    userInformation: UserInformation,
) => (prevState: AuthenticationContextState): AuthenticationContextState => {
    if (sgConnectUser && userInformation) {
        return {
            ...prevState,
            authenticatedUser: {
                icId: sgConnectUser.contact_id,
                isInternal: sgConnectUser.is_sg_group_user
                    ? sgConnectUser.is_sg_group_user.toLowerCase() === 'true'
                    : false,
            },
        };
    }

    return prevState;
};

interface SgConnectUserInfo {
    sub: string;
    zoneinfo: string;
    postal_country: string;
    mail: string;
    igg: string;
    last_name: string;
    login_ad: string;
    company_bdr_name: string;
    given_name: string;
    locale: string;
    contact_id: string;
    sgconnect_id: string;
    user_authorization: SgConnectUserAuthorization[];
    rc_local_sigle: string;
    sesame_id: string;
    user_bdr_id: string;
    company_bdr_level: string;
    name: string;
    is_sg_group_user: string;
    family_name: string;
    first_name: string;
    company_bdr_id: string;
    preferred_language: string;
    origin_network: string;
    auth_level: string;
}

interface SgConnectUserAuthorization {
    permissions: SgConnectPermission[];
}

interface SgConnectPermission {
    name: string;
}

export {
    AuthenticationProvider,
    Consumer as AuthenticationConsumer,
    context as AuthenticationContext,
};
