import * as React from 'react';
import { ErrorBoundaryProps } from './ErrorBoundary';

export const ErrorMessage: React.FC<ErrorBoundaryProps> = () => {
    return (
        <div className="alert alert-danger" role="alert">
            An unknown error has occured. Please contact{' '}
            <a href="mailto:sgmarkets@sgcib.com" className="alert-link">
                sgmarkets@sgcib.com
            </a>
        </div>
    );
};
