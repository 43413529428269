import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { getConfiguration } from 'core/configuration/configurationLoader';
import * as React from 'react';


declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-connect': any;
        }
    }
}

export const SgwtConnect = () => {
    return <WidgetLazyLoader script={`${getConfiguration().widgetCdnBaseUrl}/widgets/sgwt-connect/v4/sgwt-connect.js`}>
        <sgwt-connect />
    </WidgetLazyLoader>;
};
