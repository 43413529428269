import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { getConfiguration } from 'core/configuration/configurationLoader';
import React from 'react';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-splash-screen': any;
        }
    }
}

export const SplashScreen: React.FC = () => {
    return <WidgetLazyLoader script={`${getConfiguration().widgetCdnBaseUrl}/widgets/sgwt-splash-screen/v4/sgwt-splash-screen.js`}>
        <sgwt-splash-screen
            application="subscription"
        />
    </WidgetLazyLoader>;
};
