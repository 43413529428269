import classNames from 'classnames';
import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Subtract } from 'core/utility-types';

import {
    NotificationInfoToast,
    NotificationFailedToast,
    NotificationErrorToast,
} from '../layout/notification';

export type NotificationMessage = {
    context: 'add' | 'close' | 'message';
    message?: string;
} & (
        | {
            success: true;
            countSuccess: number;
            countError: number;
            itemsFailed: string[];
        }
        | { success: false });

type NotificationsProviderProps = {
    children?: React.ReactNode;
};
type NotificationsProviderState = Readonly<typeof initialState>;
const initialState = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    notify: (notification: NotificationMessage): void => void 0,
};

const { Provider, Consumer } = React.createContext<NotificationsProviderState>(
    initialState,
);

class NotificationsProvider extends React.Component<
    NotificationsProviderProps,
    NotificationsProviderState
> {
    public readonly state: NotificationsProviderState = initialState;

    public handleNotify = (notification: NotificationMessage) => {
        if (notification.success) {
            if (notification.countSuccess > 0) {
                toast.info(
                    <NotificationInfoToast
                        context={notification.context}
                        count={notification.countSuccess}
                    />,
                );
            }

            if (notification.countError > 0) {
                toast.error(
                    <NotificationFailedToast
                        context={notification.context}
                        count={notification.countError}
                        itemMessage={
                            notification.countError === 1
                                ? notification.itemsFailed[0]
                                : undefined
                        }
                    />,
                );
            }
        } else {
            toast.error(
                <NotificationErrorToast
                    context={notification.context}
                    message={notification.message}
                />,
            );
        }
    };

    public render() {
        return (
            <Provider
                value={{
                    ...this.state,
                    notify: this.handleNotify,
                }}
            >
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    closeButton={false}
                    icon={false}
                    newestOnTop
                    closeOnClick
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    stacked
                    toastClassName={context => classNames(
                        'toast fade show',
                        {
                            'toast-danger': context?.type === 'error',
                            'toast-info': context?.type === 'info',
                            'toast-success': context?.type === 'success',
                            'toast-warning': context?.type === 'warning',
                            'toast-primary': context?.type === 'default',
                        },
                    )}
                    bodyClassName={() => ''}
                    progressClassName={context => classNames(
                        context?.defaultClassName,
                        {
                            'bg-danger': context?.type === 'error',
                            'bg-info': context?.type === 'info',
                            'bg-success': context?.type === 'success',
                            'bg-warning': context?.type === 'warning',
                            'bg-primary': context?.type === 'default',
                        },
                    )}
                    progressStyle={{ height: '3px' }}
                />

                {this.props.children}
            </Provider>
        );
    }
}

export type WithNotifierProps = Pick<NotificationsProviderState, 'notify'>;

const withNotifier = <P extends WithNotifierProps>(
    Component: React.ComponentType<P>,
) =>
    class Notifications extends React.Component<
        Subtract<P, WithNotifierProps>> {
        public render() {
            return (
                <Consumer>
                    {({ notify }) => (
                        <Component {...this.props as P} notify={notify} />
                    )}
                </Consumer>
            );
        }
    };

export { NotificationsProvider, Consumer as Notifier, withNotifier };
