export const configPrd = {
    environment: '',
    sgConnect: {
        authorizationEndpoint: 'https://sso.sgmarkets.com/sgconnect',
        unauthorizationEndpoint: 'https://shared.sgmarkets.com/unauthorized/?redirectUri=https://insight.sgmarkets.com',
        clientId: 'db45fdf7-a80b-4fb2-ad00-fe9b0f4c9d74',
        scope: 'openid profile mail api.pretrade-gateway-subscription.v1 api.insightdomain.v1 api.pretrade-insight.v1 api.subscribe-publication.v1 api.pretrade-third-parties.v1 api.idy-widgets.request-access',
        level: 'L1',
    },
    gatewayUrl: 'https://api-s.sgmarkets.com/services/subscription/private',
    apiSubscriptionUrl: 'https://api-s.sgmarkets.com/services/insight/subscription/private',
    apiThirdPartiesUrl: 'https://api-s.sgmarkets.com/services/insight/thirdparties/private',
    icManageAccessUrl: 'https://ic.sgmarkets.com/v2/sgmaccess/{icid}',
    requestAccessId: '8ecdbd06-4c9e-4bf8-836e-3a1a180dd0c3',
    sgDashboard: {
        url: 'https://dashboard.sgmarkets.com',
        boards: {
            contactReadership: 71790,
        },
        selectors: {
            icSingleContactPicker: 614,
            icMultiContactPicker: 5807,
        },
    },
    widgetCdnBaseUrl: 'https://sgwt-cdn-widgets.sgmarkets.com',
    sharedSgMarketsBaseUrl: 'https://shared.sgmarkets.com/',
};
