import { getConfiguration } from 'core/configuration/configurationLoader';
import { loadScript } from 'core/loadScript';
import { isFunction } from 'lodash-es';
import * as React from 'react';
import { useEffect, useState } from 'react';

export interface IProps {
    script: string;
    children?: React.ReactNode | (() => React.ReactNode);
    onLoad?: () => void;
}

export const WidgetLazyLoader: React.FC<IProps> = ({ children, onLoad, script }) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            if (scriptLoaded) {
                return;
            }
            await loadScript('widget-polyfill', `${getConfiguration().widgetCdnBaseUrl}/widgets/sgwt-widget-polyfills/sgwt-widget-polyfill-webcomponent.js`, false);
            await loadScript(script, script);
            setScriptLoaded(true);

            if (onLoad) {
                onLoad();
            }
        })();
    }, []);

    if (!scriptLoaded) {
        return null;
    }
    if (isFunction(children)) {
        return <>{children()}</>;
    }
    return (
        <>{children}</>
    );
};
